import React from 'react';
import { ToastrManager } from '@so/components';
import ToastNotifierStyles from './ToastNotifierStyles';

const ToastNotifier = () => {
  return (
    <>
      <ToastrManager />
      {<ToastNotifierStyles />}
    </>
  );
};

export default ToastNotifier;
